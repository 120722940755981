import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {API_CALL_STATUS} from '../../metadata/enums'
import {
	ADD_EXPENSE,
	ADD_NEW_VEHICLE,
	DELETE_ONE_EXPENSE,
	FETCH_ALL_EXPENSES,
	FETCH_ALL_VEHICLES,
	GET_ALL_MAINTENANCES,
	UPDATE_ONE_EXPENSE,
	UPDATE_VEHICLE
} from '../actionTypes'
import axios from 'axios'
import Config from '../../config'
import {LOGGER} from "../../utils/Logger";

const initialState = {
	vehicles: [],
	maintenances: [],
	expenses: [],
	status: API_CALL_STATUS.IDLE,
	new_vehicle: false,
	updated_vehicle: false,
	error: null,
	vehicle_to_update: null,
	maintenance_to_update: null,
	expense_to_update: null,
	new_expense: false,
	updated_expense: false,
	deleted_expense: false
}

export const deleteOneExpense = createAsyncThunk(DELETE_ONE_EXPENSE, async (payload) => {
	let id = payload.data._id
	const config = {
		method: 'delete',
		url: `${Config.BACKEND_URL}expenses/${id}`,
		headers: {Authorization: payload.token}
	}
	try {
		let res = await axios(config)
		return payload.data
	} catch (err) {
		LOGGER.error('Error when getting expenses', err)
		return []
	}
})

export const fetchAllExpenses = createAsyncThunk(FETCH_ALL_EXPENSES, async (payload) => {
	const config = {
		method: 'get',
		url: `${Config.BACKEND_URL}expenses`,
		headers: {Authorization: payload.token}
	}
	try {
		let res = await axios(config)
		return res.data
	} catch (err) {
		LOGGER.error('Error when getting expenses', err)
		return []
	}
})

export const fetchAllMaintenances = createAsyncThunk(GET_ALL_MAINTENANCES, async (payload) => {
	const config = {
		method: 'get',
		url: `${Config.BACKEND_URL}maintenances`,
		headers: {Authorization: payload.token}
	}
	try {
		let res = await axios(config)
		return res.data
	} catch (err) {
		LOGGER.error('Error when getting maintenances', err)
		return []
	}
})

export const fetchAllVehicles = createAsyncThunk(FETCH_ALL_VEHICLES, async (payload) => {
	const config = {
		method: 'get',
		url: `${Config.BACKEND_URL}vehicles`,
		headers: {Authorization: payload.token}
	}
	try {
		let res = await axios(config)
		return res.data
	} catch (err) {
		LOGGER.error('Error when getting vehicles', err)
		return []
	}
})

export const addVehicleAndConfirm = createAsyncThunk(ADD_NEW_VEHICLE, async (payload) => {
	try {
		const config = {
			method: 'post',
			url: `${Config.BACKEND_URL}vehicles`,
			headers: {Authorization: payload.token, contentType: "application/json",},
			data: payload.data
		}

		let res = await axios(config)
		let data = Object.assign({}, payload.data)
		let toReturn = Object.assign({}, data, {_id: res.data?._id, stripe: res.data?.stripe})
		return toReturn
	} catch (err) {
		LOGGER.log('error when adding new vehicle', err)
	}

	// return order
})

export const updateVehicleAndConfirm = createAsyncThunk(UPDATE_VEHICLE, async (payload) => {
	try {
		let id = payload.data._id
		let url = encodeURI(`${Config.BACKEND_URL}vehicles/${id}`)
		const config = {
			method: 'put',
			url: url,
			headers: {Authorization: payload.token, contentType: "application/json",},
			data: payload.data
		}

		console.log('updating', id, payload.data)

		let res = await axios(config)
		return payload.data
	} catch (err) {
		LOGGER.log('error when adding new reservation', err)
	}

	// return order
})

export const addExpenseAndConfirm = createAsyncThunk(ADD_EXPENSE, async (payload) => {
	try {
		const config = {
			method: 'post',
			url: `${Config.BACKEND_URL}expenses`,
			headers: {Authorization: payload.token, contentType: "application/json",},
			data: payload.data
		}

		let res = await axios(config)
		let data = Object.assign({}, payload.data)
		data._id = res.data?._id
		data.created_at = new Date().toISOString()
		return data
	} catch (err) {
		LOGGER.log('error when adding new vehicle', err)
	}

	// return order
})

export const updateExpenseAndConfirm = createAsyncThunk(UPDATE_ONE_EXPENSE, async (payload) => {
	try {
		let id = payload.data._id
		const config = {
			method: 'put',
			url: `${Config.BACKEND_URL}expenses/${id}`,
			headers: {Authorization: payload.token, contentType: "application/json",},
			data: payload.data
		}
		let res = await axios(config)
		return payload.data
	} catch (err) {
		LOGGER.log('error when adding updating vehicle', err)
	}

	// return order
})


const vehiclesSlice = createSlice({
	name: 'vehicles',
	initialState,
	reducers: {
		updateNewVehicleStatus(state, action) {
			return Object.assign({}, state, {new_vehicle: action.payload})
		},
		updateDeletedExpenseStatus(state, action) {
			return Object.assign({}, state, {deleted_expense: action.payload})
		},
		setUpdatedStatus(state, action) {
			return Object.assign({}, state, {updated_vehicle: false})
		},
		updateVehicle(state, action) {
			return Object.assign({}, state, {vehicle_to_update: action.payload})
		},
		updateExpense(state, action) {
			return Object.assign({}, state, {expense_to_update: action.payload})
		},
		updateMaintenanceToUpdate(state, action) {
			return Object.assign({}, state, {maintenance_to_update: action.payload})
		},
		addNewMaintenance(state, action) {
			let temp = [...state.maintenances]
			temp.unshift(action.payload)
		},
		updateNewExpenseStatus(state, action) {
			return Object.assign({}, state, {new_expense: action.payload})
		},
		updateUpdateExpenseStatus(state, action) {
			return Object.assign({}, state, {updated_expense: action.payload})
		},
		updateMaintenance(state, action) {
			let newArray = []
			state.maintenances.forEach(entry => {
				if (entry._id === action.payload._id)
					newArray.push(action.payload)
				else
					newArray.push(entry)
			})

			return Object.assign({}, state, {maintenances: newArray})
		}
	},
	extraReducers(builder) {
		builder
		.addCase(addVehicleAndConfirm.pending, (state, action) => {
			state.status = API_CALL_STATUS.LOADING
		})
		.addCase(addVehicleAndConfirm.fulfilled, (state, action) => {
			state.status = API_CALL_STATUS.SUCCEEDED
			state.vehicles.unshift(action.payload)
			state.new_vehicle = true
		})
		.addCase(addVehicleAndConfirm.rejected, (state, action) => {
			state.status = API_CALL_STATUS.FAILED
			state.error = action.error.message
		})
		.addCase(fetchAllVehicles.pending, (state, action) => {
			state.status = API_CALL_STATUS.LOADING
		})
		.addCase(fetchAllVehicles.fulfilled, (state, action) => {
			state.status = API_CALL_STATUS.SUCCEEDED
			state.vehicles = action.payload
		})
		.addCase(fetchAllVehicles.rejected, (state, action) => {
			state.status = API_CALL_STATUS.FAILED
			state.error = action.error.message
		})
		.addCase(fetchAllExpenses.pending, (state, action) => {
			state.status = API_CALL_STATUS.LOADING
		})
		.addCase(fetchAllExpenses.fulfilled, (state, action) => {
			state.status = API_CALL_STATUS.SUCCEEDED
			let temp = action.payload.sort(function (a, b) {
				return new Date(b.created_at) - new Date(a.created_at);
			});
			state.expenses = temp
		})
		.addCase(fetchAllExpenses.rejected, (state, action) => {
			state.status = API_CALL_STATUS.FAILED
			state.error = action.error.message
		})
		.addCase(fetchAllMaintenances.pending, (state, action) => {
			state.status = API_CALL_STATUS.LOADING
		})
		.addCase(fetchAllMaintenances.fulfilled, (state, action) => {
			state.status = API_CALL_STATUS.SUCCEEDED
			state.maintenances = action.payload
		})
		.addCase(fetchAllMaintenances.rejected, (state, action) => {
			state.status = API_CALL_STATUS.FAILED
			state.error = action.error.message
		})

		.addCase(deleteOneExpense.pending, (state, action) => {
			state.status = API_CALL_STATUS.LOADING
		})
		.addCase(deleteOneExpense.fulfilled, (state, action) => {
			state.status = API_CALL_STATUS.SUCCEEDED
			let temp = []
			state.expenses.forEach(expense => {
				if (expense._id !== action.payload._id) {
					temp.push(expense)
				}
			})
			state.expenses = temp
			state.deleted_expense = true
		})
		.addCase(deleteOneExpense.rejected, (state, action) => {
			state.status = API_CALL_STATUS.FAILED
			state.error = action.error.message
		})
		.addCase(updateVehicleAndConfirm.pending, (state, action) => {
			state.status = API_CALL_STATUS.LOADING
		})
		.addCase(updateVehicleAndConfirm.fulfilled, (state, action) => {
			state.status = API_CALL_STATUS.SUCCEEDED
			let temp = []
			state.vehicles.forEach(vehicle => {
				if (vehicle._id === action.payload._id)
					temp.push(Object.assign({}, vehicle, action.payload))
				else
					temp.push(vehicle)
			})

			state.vehicles = temp
			state.updated_vehicle = true
		})
		.addCase(updateVehicleAndConfirm.rejected, (state, action) => {
			state.status = API_CALL_STATUS.FAILED
			state.error = action.error.message
		})
		.addCase(addExpenseAndConfirm.pending, (state, action) => {
			state.status = API_CALL_STATUS.LOADING
		})
		.addCase(addExpenseAndConfirm.fulfilled, (state, action) => {
			state.status = API_CALL_STATUS.SUCCEEDED
			state.expenses.unshift(action.payload)
			state.new_expense = true
			state.expense_to_update = action.payload
		})
		.addCase(addExpenseAndConfirm.rejected, (state, action) => {
			state.status = API_CALL_STATUS.FAILED
			state.error = action.error.message
		})
		.addCase(updateExpenseAndConfirm.pending, (state, action) => {
			state.status = API_CALL_STATUS.LOADING
		})
		.addCase(updateExpenseAndConfirm.fulfilled, (state, action) => {
			state.status = API_CALL_STATUS.SUCCEEDED
			let temp = []
			state.expenses.forEach(expense => {
				if (expense.id === action.payload.id)
					temp.push(Object.assign({}, expense, action.payload))
				else
					temp.push(expense)
			})

			state.expenses = temp
			state.updated_expense = true
			state.expense_to_update = action.payload
		})
		.addCase(updateExpenseAndConfirm.rejected, (state, action) => {
			state.status = API_CALL_STATUS.FAILED
			state.error = action.error.message
		})
	}

})

export const vehiclesMapSelector = (state) => {
	if (state.vehicles.vehicles && state.vehicles.vehicles.length > 0) {
		let temp = {}
		state.vehicles.vehicles.forEach(vehicle => temp[vehicle?.id] = vehicle)
		return temp
	} else {
		return {}
	}
}


export const {
	updateNewVehicleStatus,
	setUpdatedStatus,
	updateVehicle,
	updateMaintenance,
	updateMaintenanceToUpdate,
	addNewMaintenance,
	updateExpense,
	updateNewExpenseStatus,
	updateUpdateExpenseStatus,
	updateDeletedExpenseStatus
} = vehiclesSlice.actions

export default vehiclesSlice.reducer
