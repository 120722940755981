export const API_CALL_STATUS = {
	SUCCEEDED: 1,
	LOADING: 2,
	FAILED: 3,
	IDLE: 4
}

export const RENTAL_STATUS = {
	WAITING_PICKUP: 1,
	RENTING: 2,
	RETURNED: 3,
	CANCELLED: 4
}

export const CLAIM_STATUS = {
	CREATED: 1,
	ACCEPTED: 2,
	REJECTED: 3,
	CANCELLED: 4
}

export const MAINTENANCE_STATUS = {
	CREATED: 1,
	COMPLETED: 2
}

export const LOCATIONS = ['Bolingbrook', 'Indiana', 'Delivery']
export const LOCATION = 'Temp Location'
export const MILEAGE_ALLOWANCE = 100
export const MILEAGE_OVERAGE_FEE = 5

export const VEHICLE_TYPES = ['SUV', 'Sports', 'Luxury', 'Convertible']

export const PERMISSIONS = {
	view_reservations: 'View Reservations',//done
	create_reservations: 'Create Reservations',//done
	delete_reservations: 'Delete Reservations',//done
	edit_prices: 'Edit Pricing',//done
	custom_discounts: 'Custom Discounts',//done
	view_analytics: 'View Analytics',//done
	edit_vehicles: 'Edit Vehicles', //done
	insurance_claims: 'Insurance Claims',//later
	view_edit_settings: 'View & Edit Settings'//done
}

export const EMAIL_SQS_TYPE = {
	NEW_RES: 1,
	SIGN_REQUEST: 2,
	RENTAL_INVOICE: 3,
	THANK_YOU: 4,
	RENTAL_START: 5,
	RENTAL_END: 6,
	PAYMENT_RECEIPT: 7,
}

export const WEBSITE_PAYMENT_REQUIREMENTS = {
	PAYMENT: 'Payment',
	NO_PAYMENT: 'No Payment'
}

export const WEBSITE_PAYMENT_AMOUNT = {
	FULL_PAYMENT: 'Full Payment',
	PARTIAL_PAYMENT: 'Partial Payment',
	MINIMUM_PAYMENT: 'Minimum Payment'
}

export const PAYMENT_STATUSES = {
	AUTHORIZED: 'Authorized',
	CAPTURED: 'Captured',
	CHARGED: 'Charged',
	VOIDED: 'Voided',
	REFUNDED: 'Refunded',
	CANCELLED: 'Cancelled',
	OFFLINE_CHARGED: 'Offline Charged',
	OFFLINE_CANCELLED: 'Offline Cancelled',
	PARTIALLY_REFUNDED: 'Partially Refunded',
	PARTIALLY_CAPTURED: 'Partially Captured',
	FRAUD_HELD: 'Fraud Held',
	FRAUD_APPROVED: 'Fraud Approved',
	FRAUD_DECLINED: 'Fraud Declined',
	TRANSACTION_CREATED: 'Transaction Created'
};

export const LEAD_TYPES = {
	WEBSITE: 1,
	GOOGLE: 2
}

export const NOTIFICATION_TYPES = {
	WEBSITE_RES: 1,
	AXLE_AUTH_COMPLETE: 2
}
