const Config = {
	// BACKEND_URL: 'http://localhost:9000/',
	// CONTRACT_URL: 'http://localhost:3006/',
	// BACKEND_URL: 'https://s0nwvs979h.execute-api.us-west-2.amazonaws.com/test/',

	// AUTHORIZENET_URL: 'https://apitest.authorize.net/xml/v1/request.api/',
	// CONTRACT_URL: 'https://contracts-test-real-testsite.web.app/',
	// BACKEND_URL: 'https://1e5ru2rj20.execute-api.us-west-2.amazonaws.com/test2/',
	// ENV: 'test',
	// BUSINESS: 'test',
	// NAME: 'Test Rentals',


	CONTRACT_URL: 'https://contracts.golux.app/',
	AUTHORIZENET_URL: 'https://api.authorize.net/xml/v1/request.api/',

	// demo account
	// BACKEND_URL: 'https://glqmgqi5h4.execute-api.us-west-2.amazonaws.com/prod/',
	// ENV: 'demo',
	// BUSINESS: 'demo',
	// NAME: 'GoLux Rentals',


	//dde things
	// BACKEND_URL: 'https://nbz1rdwbo6.execute-api.us-west-2.amazonaws.com/prod/',
	// ENV: 'prod',
	// BUSINESS: 'dde',
	// NAME: 'DDE Rentals',

	//drive LA things
	BACKEND_URL: 'https://z90ld6ouq9.execute-api.us-west-2.amazonaws.com/prod/',
	ENV: 'drivela',
	BUSINESS: 'drivela',
	NAME: 'Drive LA',

	//quality rentalz
	// BACKEND_URL: 'https://gzlalegiqj.execute-api.us-west-2.amazonaws.com/prod/',
	// ENV: 'quality',
	// BUSINESS: 'quality',
	// NAME: 'Quality Rentalz',

	// // AMD AUTO
	// BACKEND_URL: 'https://pk14l34rk8.execute-api.us-west-2.amazonaws.com/prod/',
	// ENV: 'amd',
	// BUSINESS: 'amd',
	// NAME: 'AMD AUTO GROUP',

	// unknown client gham
	// BACKEND_URL: 'https://5zjz3hjqkf.execute-api.us-west-2.amazonaws.com/prod/',
	// ENV: 'gham',
	// BUSINESS: 'gham',
	// NAME: 'Unknown Rentals',

	//drive LA 2nd location
	// BACKEND_URL: 'https://l466fg76ik.execute-api.us-west-2.amazonaws.com/prod/',
	// ENV: 'drivela2',
	// BUSINESS: 'drivela2',
	// NAME: 'Drive LA',

	//LA Exotic Retnasl
	// BACKEND_URL: 'https://95wpap5jb7.execute-api.us-west-2.amazonaws.com/prod/',
	// ENV: 'la-exotics',
	// BUSINESS: 'la-exotics',
	// NAME: 'LA Exotic Rentals',

	//peacock rentals
	// BACKEND_URL: 'https://tjg60t43t5.execute-api.us-west-2.amazonaws.com/prod/',
	// ENV: 'peacock',
	// BUSINESS: 'peacock',
	// NAME: 'Peacock Rentals',

	//fantasy exotics url and env
	// BACKEND_URL: 'https://12yw54h3th.execute-api.us-west-2.amazonaws.com/prod/',
	// ENV: 'fantasy',
	// BUSINESS: 'fantasy',
	// NAME: 'Fantasy Exotics',

	// BACKEND_URL: 'https://33cbi9ny36.execute-api.us-east-2.amazonaws.com/prod/', // old test url
	// /*ENV: 'demo-prod',*/


	//777 exotics
	// BACKEND_URL: 'https://punnx1809a.execute-api.us-west-2.amazonaws.com/prod/',
	// env: '777exotics',

	//sde exotics
	// BACKEND_URL: 'https://nci5oqf6g1.execute-api.us-west-2.amazonaws.com/prod/',
	// env: 'sd-exotics',


	AWS_CLOUDFRONT_URL: 'https://d3nk8x6ofxzl5v.cloudfront.net/',
	GOOGLE_API_KEY: "AIzaSyAlmct1SHhXm2OgLlUfShrHZjNFj3fvDNM",
	WHITELIST_UIDS: [
		'dtFyaZpli2RmdRL2s6MEwFkatAm2', //support from old firebase project
		'8OROqsfWm5W5X3SBrj5EmGDrXjF2', //support@gmail.com
		'iUDUuOEqQUhBFxdfMqoIfEU30sR2', //info@dderentlas new
		'OfZUU493wmaP3oV9J0uHm9qf98G2',//info@drive-la.com
		'EKmi7ULrDkaF7N2lTdSRijGZ3Jr1', //info@fantasyexoticrentals.com
		'PlxpoPdunJhkj8M6FA4kLcl01oD3', //info@peacock-rentals.com
		'DUtbTHE9EGVZiDXIt6sOosbbsD63', //quality rentalz concierge
		'FvK0FyCNibeU4lNDTBKhw0JA8yv1', //amdautogroupinc@gmail.com AMT AUTO GROUP
	],
	AWS_REGION: 'us-west-2',

	AWS_ACCESS_KEY_ID: "AKIAW4FID5CQF6STTWEH",
	AWS_SECRET_ACCESS_KEY: "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",
	FCM_KEY: "BNJxF--5eocbJGxiGV3W9h123FnajQr_wGjqnsexx-wD7nGdiFv33yU7Q6n1Mw8gAVG1MsS0WZZhW9UW5c9Zoxs",
	RANDOM: 'SmvpTKEyH6j0', //for admin project
}

export default Config
