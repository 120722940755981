import React, {useEffect, useState} from 'react'
import GeneralStyles from "../../routes/GeneralStyles.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {API_CALL_STATUS, PAYMENT_STATUSES} from "../../metadata/enums";
import Styles from "./styles/index.module.scss";
import CustomModal from "../../components/general/CustomModal";
import CustomSelect from "../general/CustomSelect";
import CustomLoaderSpinner from "../general/CustomLoaderSpinner";
import Config from "../../config";
import axios from "axios";
import {LOGGER} from "../../utils/Logger";
import {reservationsMapSelector} from "../../redux/slices/reservationsSlice";
import Helpers from '../../utils/helpers'
import {getPaymentsSettings, getPriceSettings} from "../../redux/slices/settingsSlice";
import {vehiclesMapSelector} from "../../redux/slices/vehiclesSlice";
import Colors from "../../config/colors";
import Constants from "../../config/constants";
import CustomTextFieldNew from "../general/CustomTextFieldNew";
import visaImage from '../../assets/logos/card_visa.png';
import mastercardImage from '../../assets/logos/card_mastercard.png';
import amexImage from '../../assets/logos/card_amex.png';
import discoverImage from '../../assets/logos/card_discover.png';
import {clientsMapSelector, getOneClient} from "../../redux/slices/clientsSlice";
import CustomButtonContained from "../general/CustomButtonContained";
import {getUsersUidMap} from '../../redux/slices/userSlice'
import {addPayment} from '../../redux/slices/paymentsSlice'
import ApiHelpers from '../../utils/ApiHelpers'


export default function CapturePaymentModal({
																							paymentType,
																							modalIsOpen,
																							onModalClose,
																							successCallback,
																							errorCallback,
																							reservation
																						}) {
	const dispatch = useDispatch()

	const token = useSelector(state => state.auth.token)
	const reservationsState = useSelector(state => state.reservations)
	const reservationsMap = useSelector(state => reservationsMapSelector(state.reservations))
	const vehiclesMap = useSelector(state => vehiclesMapSelector(state))
	const priceSettings = useSelector(state => getPriceSettings(state.settings))
	const clientsMap = useSelector(state => clientsMapSelector(state.clients))
	const usersUidMap = useSelector((state) => getUsersUidMap(state.user));
	const mongoUser = useSelector(state => state.user.mongoUser)
	const paymentSettings = useSelector(state => getPaymentsSettings(state.settings))

	const [tab, setTab] = useState(0)
	const [isSecurity, setIsSecurity] = useState(false)
	const [allRes, setAllRes] = useState([])
	const [selectedRes, setSelectedRes] = useState(null)
	const [showLoader, setShowLoader] = useState(false)
	const [payments, setPayments] = useState([])
	const [balancePaid, setBalancePaid] = useState(0);
	const [outstandingBalance, setOutstandingBalance] = useState(0);
	const [balancePayable, setBalancePayable] = useState(0);
	const [securityCaptured, setSecurityCaptured] = useState(0);
	const [securityHold, setSecurityHold] = useState(0);
	const [outstandingSecurityBalance, setOutstandingSecurityBalance] = useState(0);
	const [securityPayable, setSecurityPayable] = useState(0);
	const [cardsLoading, setCardsLoading] = useState(false)
	const [cardsOnFile, setCardsOnFile] = useState([])

	const [errorMessage, setErrorMessage] = useState('')
	const [showError, setShowError] = useState(false)
	const [showSuccess, setShowSuccess] = useState(false)
	const [successMessage, setSuccessMessage] = useState('')

	const [cardType, setCardType] = useState({type: 'unknown', image: null});

	//new card fields
	const [cardName, setCardName] = useState('');
	const [cardNumber, setCardNumber] = useState('');
	const [expiry, setExpiry] = useState('');
	const [cvv, setCVV] = useState("")
	const [billingAddress, setBillingAddress] = useState("")
	const [city, setCity] = useState('')
	const [state, setState] = useState('')
	const [zip, setZip] = useState('')
	const [country, setCountry] = useState('USA')

	const [selectedClient, setSelectedClient] = useState(null)
	const [selectedVehicle, setSelectedVehicle] = useState(null)
	const [selectedCardIndex, setSelectedCardIndex] = useState(-1)
	const [showCheckout, setShowCheckout] = useState(false)
	const [email, setEmail] = useState('')
	const [productId, setProductId] = useState(null)

	const [isPaymentStripe, setIsPaymentStripe] = useState(true)

	useEffect(() => {
		window.addEventListener('message', (event) => {
			console.log('got new event', event)
		})
	}, [])

	useEffect(() => {
		if (!paymentSettings)
			return null

		setIsPaymentStripe(paymentSettings.payments_stripe)

	}, [paymentSettings]);

	useEffect(() => {
		setIsSecurity(paymentType === 'security')
	}, [paymentType]);

	useEffect(() => {
		if (reservationsState.status !== API_CALL_STATUS.LOADING || reservationsState.status !== API_CALL_STATUS.FAILED) {
			setAllRes(reservationsState.reservations.concat(reservationsState.old_reservations))
		}
	}, [reservationsState])

	useEffect(() => {
		if (reservation)
			setSelectedRes({label: `Res #${reservation._id}-${reservation.vehicle_id}`, value: reservation._id})
	}, [reservation])

	useEffect(() => {
		if (selectedRes && selectedRes.value) {
			setShowLoader(true)
			ApiHelpers.getPaymentsForRes(token, selectedRes.value)
			.then(data => {
				setPayments(data)
				setShowLoader(false)
			}).catch(err => {
				LOGGER.error('Error when getting payments for reservation', err)
				errorCallback(err?.message || "Error when getting payments for reservation")
			})

			const reservation = reservationsMap[selectedRes?.value] || {}
			const client = clientsMap[reservation.client]
			const vehicle = vehiclesMap[reservation.vehicle_id]
			setSelectedVehicle(vehicle)
			setSelectedClient(client)
			setEmail(client?.email)
			setProductId(vehicle.stripe?.product_id)
		}

	}, [selectedRes]);

	useEffect(() => {
		let reservation = reservationsMap[selectedRes?.value] || {}
		let vehicle = vehiclesMap[reservation.vehicle_id]
		let pricing;
		let totalPrice
		if (reservation.isChauffeur) {
			pricing = Helpers.getChauffeurPricingObject(reservation, vehicle, priceSettings)
			let temp = Object.assign({}, reservation, {pricing: pricing})
			totalPrice = Helpers.getChauffeurTotalPricing(temp)
		} else {
			pricing = Helpers.getRentalsPricingObject(reservation, vehicle, priceSettings)
			console.log('pricing is', pricing)
			let temp = Object.assign({}, reservation, {pricing: pricing})
			totalPrice = Helpers.getRentalTotalPricing(temp)
		}


		const totalSecurity = pricing?.deposit || 0;

		if (payments && payments.length > 0) {
			let totalHold = 0;
			let totalCaptured = 0;
			let totalPaid = 0;

			payments.forEach((trans) => {
				if (trans.isSecurityHold) {
					if (trans.transactionStatus === PAYMENT_STATUSES.AUTHORIZED) totalHold += Number(trans.amount);
					else if (trans.transactionStatus === PAYMENT_STATUSES.CAPTURED || trans.transactionStatus === PAYMENT_STATUSES.OFFLINE_CHARGED) totalCaptured += Number(trans.amount);
					else if (trans.transactionStatus === PAYMENT_STATUSES.PARTIALLY_CAPTURED) totalCaptured += Number(trans.captureAmount);
				} else {
					if (
						trans.transactionStatus === PAYMENT_STATUSES.CHARGED
						|| trans.transactionStatus === PAYMENT_STATUSES.OFFLINE_CHARGED
						|| trans.transactionStatus === PAYMENT_STATUSES.FRAUD_APPROVED
					) {
						totalPaid += Number(trans.amount);
					} else if (trans.transactionStatus === PAYMENT_STATUSES.PARTIALLY_REFUNDED) {
						totalPaid += Number(trans.amount) - Number(trans.refundAmount);
					}
				}
			});

			const outstandingBalance = totalPrice - totalPaid;
			setOutstandingBalance(outstandingBalance > 0 ? outstandingBalance.toFixed(2) : 0);
			console.log('hey hey', totalPrice, outstandingBalance)
			setBalancePayable(outstandingBalance > 0 ? outstandingBalance.toFixed(2) : 0);
			setBalancePaid(totalPaid.toFixed(2));

			const outstandingSecurity = totalSecurity - totalHold - totalCaptured;
			setOutstandingSecurityBalance(outstandingSecurity > 0 ? outstandingSecurity.toFixed(2) : 0);
			setSecurityPayable(outstandingSecurity > 0 ? outstandingSecurity.toFixed(2) : 0);
			setSecurityHold(totalHold.toFixed(2));
			setSecurityCaptured(totalCaptured.toFixed(2));
		} else {
			setOutstandingSecurityBalance(Number(pricing?.deposit).toFixed(2));
			setSecurityPayable(Number(pricing?.deposit).toFixed(2));
			setSecurityHold(0);
			setSecurityCaptured(0);

			setOutstandingBalance(totalPrice.toFixed(2));
			setBalancePayable(totalPrice.toFixed(2));
			setBalancePaid(0);
		}
	}, [payments]);

	const getCardsOnFile = async () => {
		try {
			setCardsLoading(true);
			const customerProfileConfig = {
				method: 'post',
				url: `${Config.BACKEND_URL}getCustomerProfile`, // replace with config backend url
				data: {customerProfileId: selectedClient?.customerProfileId},
				headers: {Authorization: token},
			};
			const response = await axios(customerProfileConfig);
			LOGGER.log('customer Profiles API Response:', response.data);
			setCardsOnFile(response.data?.profile?.paymentProfiles || []);
			if (response.data && response.data.length > 0)
				setSelectedCardIndex(0)
			setCardsLoading(false);
		} catch (error) {
			setCardsLoading(false);
			LOGGER.error('API Error:', error);
			setErrorMessage('Error when getting cards on file', error.message)
			setShowError(true)
		}
	};


	const handleCardChange = (text) => {
		const formattedText = text.replace(/ /g, '');
		let inputNumbersOnly = formattedText.replace(/\D/g, '');

		if (inputNumbersOnly.length > 16) {
			inputNumbersOnly = inputNumbersOnly.substr(0, 16);
		}

		const splits = inputNumbersOnly.match(/.{1,4}/g);

		let spacedNumber = '';
		if (splits) {
			spacedNumber = splits.join(' ');
		}

		setCardType(getCardType(spacedNumber));
	};

	const getCardType = (cardNumber) => {
		const cardTypes = [
			{
				type: 'visa',
				pattern: /^4/,
				image: visaImage,
			},
			{
				type: 'mastercard',
				pattern: /^5[1-5]/,
				image: mastercardImage,
			},
			{
				type: 'amex',
				pattern: /^3[47]/,
				image: amexImage,
			},
			{
				type: 'discover',
				pattern: /^6(?:011|5)/,
				image: discoverImage,
			},
		];

		for (let i = 0; i < cardTypes.length; i++) {
			if (cardNumber.match(cardTypes[i].pattern)) {
				return cardTypes[i];
			}
		}
		return {type: 'unknown', image: null};
	};

	const luhnCheck = (val) => {
		let checksum = 0; // running checksum total
		let j = 1; // takes value of 1 or 2

		// Process each digit one by one starting from the last
		for (let i = val.length - 1; i >= 0; i--) {
			let calc = 0;
			// Extract the next digit and multiply by 1 or 2 on alternative digits.
			calc = Number(val.charAt(i)) * j;

			// If the result is in two digits add 1 to the checksum total
			if (calc > 9) {
				checksum += 1;
				calc -= 10;
			}

			// Add the units element to the checksum total
			checksum += calc;

			// Switch the value of j
			if (j === 1) {
				j = 2;
			} else {
				j = 1;
			}
		}

		// Check if it is divisible by 10 or not.
		return checksum % 10 === 0;
	};

	const validateCardNumber = (number) => {
		if (!number || number.length === 0) {
			return false;
		}
		// Check if the number contains only numeric value
		// and is of between 13 to 19 digits
		const regex = new RegExp('^[0-9]{13,19}$');
		if (!regex.test(number.replace(/ /g, ''))) {
			return false;
		}
		if (cvv.length !== 3 && cvv.length !== 4) return false;
		if (expiry.length !== 5 || expiry.split('/').length !== 2) return false
		return luhnCheck(number.replace(/ /g, ''));
	};

	const validateDate = () => {
		if (cardName.length === 0) {
			setErrorMessage('Please enter the full name on the card ')
			return false
		}


		if (!validateCardNumber(cardNumber)) {
			setErrorMessage('Invalid card details')
			return false
		}

		if (!billingAddress || billingAddress.length === 0) {
			setErrorMessage('Please enter billing address')
			return false
		}

		if (!city || city.length === 0) {
			setErrorMessage('Please enter billing city')
			return false
		}

		if (!state || state.length === 0) {
			setErrorMessage('Please enter billing state')
			return false
		}

		if (!zip || zip.length === 0) {
			setErrorMessage('Please enter billing zip code')
			return false
		}

		if (!country || country.length === 0) {
			setErrorMessage('Please enter billing country')
			return false
		}

		let amount = isSecurity ? securityPayable : balancePayable
		if (!amount || isNaN(amount) || Number(amount) === 0) {
			setErrorMessage(`Please enter the amount to ${isSecurity ? 'put on hold' : 'process payment'}`)
			return false
		}

		return true

	}

	const onProcessPaymentExistingCardClicked = async () => {
		if (!cardsOnFile || cardsOnFile.length === 0 || selectedCardIndex < 0) {
			setErrorMessage('Please select a card from the list if available')
			setShowError(true)
			return
		}

		setShowError(false)
		if (isSecurity)
			await placeSecurityHoldSavedCard()
		else
			await chargeSavedCardForCustomer()


	}

	const chargeSavedCardForCustomer = async () => {
		setCardsLoading(true);
		const salesPerson =
			usersUidMap && usersUidMap[mongoUser.uid]
				? usersUidMap[mongoUser.uid].name
				: 'Admin';
		let reservation = reservationsMap[selectedRes?.value] || {}
		const payload = {
			clientId: selectedClient._id,
			customerProfileId: selectedClient.customerProfileId,
			customerPaymentProfileId: cardsOnFile[selectedCardIndex]?.customerPaymentProfileId,
			reservationId: reservation._id,
			vehicleId: reservation.vehicle_id,
			amount: Number(balancePayable),
			salesPerson,
		};
		const config = {
			method: 'post',
			url: `${Config.BACKEND_URL}chargeCustomerProfile`,
			data: payload,
			headers: {Authorization: token},
		};
		try {
			const res = await axios(config);
			LOGGER.log('chargeCustomerResp > ', res);
			setCardsLoading(false);
			if (res.data?._id) {
				setSuccessMessage('Successfully charged customer profile')
				setShowSuccess(true)
				dispatch(addPayment(res.data))
				dispatch(getOneClient({token, _id: selectedClient._id}));
				successCallback('Successfully charged customer profile')
			} else {
				setErrorMessage('Something went wrong when charging customer profile')
				setShowError(true)
			}
		} catch (err) {
			setCardsLoading(false)
			setErrorMessage('Something went wrong whem charging customer profile')
			setShowError(true)
		}
	};

	const placeSecurityHoldSavedCard = async () => {
		setCardsLoading(true);
		const salesPerson = usersUidMap && usersUidMap[mongoUser.uid] ? usersUidMap[mongoUser.uid].name : 'Admin';
		let reservation = reservationsMap[selectedRes?.value] || {}
		const payload = {
			clientId: selectedClient?._id,
			customerProfileId: selectedClient?.customerProfileId,
			customerPaymentProfileId:
			cardsOnFile[selectedCardIndex].customerPaymentProfileId,
			reservationId: reservation._id,
			vehicleId: reservation.vehicle_id,
			amount: Number(securityPayable),
			salesPerson,
		};
		const config = {
			method: 'post',
			url: `${Config.BACKEND_URL}authorizeCustomerProfile`,
			data: payload,
			headers: {Authorization: token},
		};
		try {
			const res = await axios(config);
			LOGGER.log('authorize customer response > ', res);
			setCardsLoading(false);
			if (res.data?._id) {
				setSuccessMessage('Successfully placed hold on customer profile')
				setShowSuccess(true)
				dispatch(addPayment(res.data))
				dispatch(getOneClient({token, _id: selectedClient._id}));
				successCallback('Successfully placed hold on customer profile')
			} else {
				setErrorMessage('Something went wrong when placing security hold')
				setShowError(true)
			}
		} catch (err) {
			setCardsLoading(false)
			setErrorMessage('Something went wrong when placing security hold')
			setShowError(true)
		}
	};

	const onProcessPaymentClicked = async () => {
		if (!validateDate()) {
			setShowError(true)
			return
		}
		setShowError(false)
		if (isSecurity)
			await placeHoldNewCardForCustomer()
		else
			await chargeNewCardForCustomer()
	}

	const placeHoldNewCardForCustomer = async () => {
		setShowLoader(true);
		let chargeResponse
		try {
			chargeResponse = await placeSecurityHoldNewCard();
			setShowLoader(false)
			if (selectedClient?.customerProfileId) {
				console.log('customer profile exists');
				await createCustomerPaymentProfile();
			} else {
				console.log('customer profile needs to be created');
				await createCustomerProfile();
			}
			setSuccessMessage('Successfully placed hold on new card')
			setShowSuccess(true)
			dispatch(addPayment(chargeResponse))
			dispatch(getOneClient({token, _id: selectedClient._id}));
			successCallback('Successfully placed hold on new card')
		} catch (err) {
			setShowLoader(false)
			LOGGER.error('Error when placing hold', err);
			if (typeof err === 'string' && err === 'duplicate') {
				//the payment went thrpough but the user profile could notbe created coz of duplicate email
				console.log('authorized card but could not create customer profile')
				setSuccessMessage('Successfully placed hold on new card')
				setShowSuccess(true)
				dispatch(addPayment(chargeResponse))
				dispatch(getOneClient({token, _id: selectedClient._id}));
				successCallback('Successfully placed hold on new card')
			} else {
				setErrorMessage('Something went wrong placing hold')
				setShowError(true)
				errorCallback(err?.message || "Something went wrong while placing authorization hold on new card")
			}


		}
	}

	const placeSecurityHoldNewCard = async () => {
		setShowLoader(true);
		return new Promise(async (resolve, reject) => {
			const salesPerson = usersUidMap && usersUidMap[mongoUser.uid] ? usersUidMap[mongoUser.uid].name : 'Admin';
			let reservation = reservationsMap[selectedRes?.value] || {}
			const payload = {
				firstName: cardName.split(' ', 2)[0],
				lastName: cardName.split(' ', 2)[1],
				clientId: selectedClient?._id,
				reservationId: reservation._id,
				vehicleId: reservation.vehicle_id,
				address: billingAddress,
				city,
				state,
				zip,
				country,
				salesPerson,
				payment: {
					amount: Number(securityPayable),
					creditCard: {
						cardNumber: cardNumber.replace(/\s/g, ''),
						expirationDate: expiry.replace('/', ''),
						cardCode: cvv,
					},
				},
				token,
			};
			const config = {
				method: 'post',
				url: `${Config.BACKEND_URL}payments/online/deposit`,
				data: payload,
				headers: {Authorization: payload.token},
			};

			try {
				const res = await axios(config);
				LOGGER.log('auth card resp > ', res);
				if (res.data?._id) {
					LOGGER.log('successfully placed the auth hold');
					setSuccessMessage('Successfully placed authorization hold')
					setShowSuccess(true)
					resolve(res.data);
				}
			} catch (err) {
				LOGGER.error('error when placing auth hold', err);
				reject(err)
			}
		})
	};

	const chargeNewCardForCustomer = async () => {
		setShowLoader(true);
		let chargeResponse
		try {
			chargeResponse = await chargeCard();
			setShowLoader(false)
			if (selectedClient?.customerProfileId) {
				console.log('customer profile exists');
				await createCustomerPaymentProfile();
			} else {
				console.log('customer profile needs to be created');
				await createCustomerProfile();
			}
			setSuccessMessage('Successfully charged the card')
			setShowSuccess(true)
			dispatch(addPayment(chargeResponse))
			dispatch(getOneClient({token, _id: selectedClient._id}));
			successCallback('Successfully charged the card')
		} catch (err) {
			setShowLoader(false)
			LOGGER.error('Error when charging card', err);
			if (typeof err === 'string' && err === 'duplicate') {
				//the payment went thrpough but the user profile could notbe created coz of duplicate email
				console.log('charged card but could not create customer profile')
				setSuccessMessage('Successfully charged the card')
				setShowSuccess(true)
				dispatch(addPayment(chargeResponse))
				dispatch(getOneClient({token, _id: selectedClient._id}));
				successCallback('Successfully charged the card')
			} else {
				setErrorMessage('Something went wrong')
				setShowError(true)
				errorCallback(err?.message || "Something went wrong while charging new card for customer")
			}

		}
	};

	const createCustomerPaymentProfile = async () => new Promise(async (resolve, reject) => {
		const paymentProfilePayload = {
			firstName: cardName.split(' ', 2)[0],
			lastName: cardName.split(' ', 2)[1],
			clientId:
			selectedClient?._id,
			customerProfileId: selectedClient.customerProfileId,
			address: billingAddress,
			city,
			state,
			zip,
			country,
			payment: {
				creditCard: {
					cardNumber: cardNumber.replace(/\s/g, ''),
					expirationDate: expiry.replace('/', ''),
				},
			},
			token,
		};

		const paymentProfileConfig = {
			method: 'post',
			url: `${Config.BACKEND_URL}createCustomerPaymentProfile`,
			data: paymentProfilePayload,
			headers: {
				Authorization: paymentProfilePayload.token,
			},
		};

		try {
			const paymentProfileResponse = await axios(paymentProfileConfig);
			LOGGER.log('createCustomerPaymentProfile', paymentProfileResponse);
			if (paymentProfileResponse.data?.messages?.resultCode === 'Ok'
				|| paymentProfileResponse.data?.messages?.message[0]?.code === 'E00039') {
				resolve();
			} else {
				reject();
			}
		} catch (err) {
			LOGGER.error('Error when creating customer payment profile', err);
			reject(err);
		}
	});


	const createCustomerProfile = async () => new Promise(async (resolve, reject) => {
		let reservation = reservationsMap[selectedRes?.value] || {}
		const customerPayload = {
			firstName: cardName.split(' ', 2)[0],
			lastName: cardName.split(' ', 2)[1],
			clientId: selectedClient?._id,
			address: billingAddress,
			city,
			state,
			zip,
			country,
			customerData: {
				customerId: selectedClient?.id,
				email: selectedClient?.email,
				creditCard: {
					cardNumber: cardNumber.replace(/\s/g, ''),
					expirationDate: expiry.replace('/', ''),
				},
			},
			token,
		};
		const customerConfig = {
			method: 'post',
			url: `${Config.BACKEND_URL}createCustomerProfile`,
			data: customerPayload,
			headers: {Authorization: customerPayload.token},
		};

		try {
			const customerResponse = await axios(customerConfig);
			LOGGER.log('createCustomerProfile', customerResponse.data);
			if (customerResponse.data.messages.resultCode === 'Ok') {
				resolve();
			} else if (customerResponse.data?.messages?.message[0]?.code === 'E00039') {
				reject('duplicate');
			} else {
				reject();
			}
		} catch (err) {
			LOGGER.error('Error when creating customer profile', err);
			reject(err);
		}
	});

	const chargeCard = async () => new Promise(async (resolve, reject) => {
		const salesPerson = usersUidMap && usersUidMap[mongoUser.uid] ? usersUidMap[mongoUser.uid].name : 'Admin';
		let reservation = reservationsMap[selectedRes?.value] || {}
		const payload = {
			firstName: cardName.split(' ', 2)[0],
			lastName: cardName.split(' ', 2)[1],
			clientId: reservation.client,
			reservationId: reservation._id,
			vehicleId: reservation.vehicle_id,
			address: billingAddress,
			city,
			state,
			zip,
			country,
			salesPerson,
			payment: {
				amount: Number(balancePayable),
				creditCard: {
					cardNumber: cardNumber.replace(/\s/g, ''),
					expirationDate: expiry.replace('/', ''),
					cardCode: cvv
				},
			},
			token,
		};

		const chargeConfig = {
			method: 'post',
			url: `${Config.BACKEND_URL}payments/online/balance`, // replace with config backend url
			data: payload,
			headers: {Authorization: payload.token},
		};

		try {
			const chargeResponse = await axios(chargeConfig);
			if (chargeResponse.data?._id) {
				LOGGER.log('successfully charged the card');
				setSuccessMessage('Successfully charged the card')
				setShowSuccess(true)
				LOGGER.log('charge card response > ', chargeResponse);
				resolve(chargeResponse.data);
			}
		} catch (err) {
			LOGGER.error('Error when charging card', err);
			reject(err);
		}
	});

	const onShowCheckoutClicked = () => {
		if (!Helpers.validateEmail(email)) {
			setErrorMessage('Please enter a valid email address')
			setShowError(true)
			return
		}

		if (!isSecurity && isPaymentStripe) {
			if (!selectedVehicle?.stripe?.product_id) {
				setErrorMessage('Missing stripe product ID')
				setShowError(true)
				return
			}
		}


		let payable = isSecurity ? securityPayable : balancePayable
		if (isNaN(payable) || Number(payable) <= 0) {
			setErrorMessage('Please enter a valid amount')
			setShowError(true)
			return
		}


		setShowCheckout(true)
	}

	const renderCardRow = (card, index) => {
		return (
			<div
				key={index.toString()}
				onClick={() => {
					console.log('clicked', index)
					setSelectedCardIndex(index)
				}}
				className={Styles.cardRow}
				style={{
					borderColor: index === selectedCardIndex ? Colors.themeLight : 'gray',
					borderWidth: index === selectedCardIndex ? 2 : 0.5
				}}>
				<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
					<label style={{
						color: Colors.primaryTextColor, width: 100,
					}}>{card?.payment?.creditCard?.cardType ? card?.payment?.creditCard?.cardType.toUpperCase() : ''}</label>
					<label style={{
						color: Colors.primaryTextColor,
					}}>{card?.payment?.creditCard?.cardNumber}</label>
				</div>

				<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
					<label style={{
						color: Colors.primaryTextColor, width: 100,
					}}></label>
					<label style={{
						color: Colors.secondaryTextColor,
					}}>XX/XX</label>
				</div>
			</div>
		)
	}

	const renderExistingCards = () => {
		let cards
		if (cardsOnFile && cardsOnFile.length > 0) {
			cards = cardsOnFile.map((card, index) => renderCardRow(card, index))
		} else {
			cards =
				<label style={{marginTop: 20, width: '100%', textAlign: 'center', color: Colors.theme}}>There are no
					cards on
					file</label>
		}

		return (
			<div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
				{
					cardsLoading ?
						<div
							style={{
								height: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								marginTop: 50
							}}>
							<CustomLoaderSpinner/>
						</div>
						:
						<div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
							<div
								style={{
									height: '30vh',
									display: 'flex',
									marginTop: 20,
									width: '100%',
									overflowY: 'scroll',
									flexDirection: 'column',
								}}>
								{cards}
							</div>
							<CustomButtonContained
								text={isSecurity ? 'Process Authorization Hold' : 'Process Payment'}
								onClick={onProcessPaymentExistingCardClicked}
								style={{marginTop: 2}}
								color={'primary'}
							/>
						</div>
				}


			</div>
		)
	}

	const renderNewCardForm = () => {
		return (
			<div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
				<CustomTextFieldNew
					marginTop={10}
					width={'98%'}
					label={'Name On Card'}
					placeholder={'full name'}
					value={cardName}
					onChange={setCardName}
				/>

				<div style={{width: '100%', position: 'relative', marginTop: 10, left: '-0.5%'}}>
					<CustomTextFieldNew
						width={'98%'}
						label={'Card Number'}
						placeholder={'card number'}
						value={cardNumber}
						onChange={(text) => {
							if (text.length < 20) {
								const value = text
								.replace(/[^0-9]/gi, '')
								.replace(/(.{4})/g, '$1 ')
								.trim();
								setCardNumber(value);
							}
							handleCardChange(text)
						}}
					/>
					{
						cardType && cardType.image &&
						<img src={cardType.image}
								 style={{position: 'absolute', right: 10, width: 50, height: 50, top: 0}}></img>
					}

				</div>


				<div style={{
					width: '101%',
					marginTop: 10,
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'space-between'
				}}>
					<CustomTextFieldNew
						width={'50%'}
						label={'Card Expiry'}
						placeholder={'mm/yy'}
						value={expiry}
						onChange={(text) => {
							const number = text.replace('/', '')
							if (isNaN(number)) {
								return
							}
							setExpiry(Helpers.getFormattedExpiry(text, expiry))
						}}
					/>

					<CustomTextFieldNew
						hidden={true}
						width={'50%'}
						label={'CVV'}
						placeholder={'cvv'}
						value={cvv}
						onChange={(text) => {
							if (isNaN(text) || text.length > 4)
								return
							setCVV(text)
						}}
					/>
				</div>


				<CustomTextFieldNew
					marginTop={10}
					width={'98%'}
					label={'Billing Address'}
					value={billingAddress}
					placeholder={'full address'}
					onChange={setBillingAddress}
				/>

				<div
					style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', marginTop: 10}}>
					<CustomTextFieldNew
						width={'50%'}
						label={'City'}
						value={city}
						placeholder={'billing city'}
						onChange={setCity}
					/>
					<CustomTextFieldNew
						width={'50%'}
						label={'State'}
						value={state}
						placeholder={'billing state'}
						onChange={setState}
					/>
				</div>

				<div
					style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', marginTop: 10}}>
					<CustomTextFieldNew
						width={'50%'}
						label={'Zip Code'}
						value={zip}
						placeholder={'billing zip'}
						onChange={setZip}
					/>
					<CustomTextFieldNew
						width={'50%'}
						label={'Country'}
						value={country}
						placeholder={'billing country'}
						onChange={setCountry}
					/>
				</div>

				<CustomButtonContained
					text={isSecurity ? 'Process Authorization Hold' : 'Process Payment'}
					onClick={onProcessPaymentClicked}
					style={{marginTop: 2}}
					color={'primary'}
				/>


			</div>
		)
	}

	const renderPaymentForm = () => {
		if (showCheckout) {
			const salesPerson =
				usersUidMap && usersUidMap[mongoUser.uid]
					? usersUidMap[mongoUser.uid].name
					: 'Admin';
			let url =
				`${Config.CONTRACT_URL}stripe/checkout?
				bus=${Config.BUSINESS}
				&email=${email}
				&productId=${productId}
				&token=${token}
				&clientId=${selectedClient?._id}
				&vehicleId=${selectedVehicle?.id}
				&resId=${selectedRes?.value}
				&salesPerson=${salesPerson}
				&isSecurity=${isSecurity}
				&amount=${isSecurity ? Number(securityPayable).toFixed(2) : Number(balancePayable).toFixed(2)}`
			return (
				<div style={{width: '100%', height: '100%'}}>
					<div style={{
						width: '39%',
						display: 'flex',
						alignItems: 'center',
						flexDirection: 'column',
						margin: '0 auto'
					}}>
						{
							isSecurity ?
								<div style={{
									width: '100%',
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'space-between',
									marginTop: 10
								}}>
									<div>
										<label style={{color: Colors.secondaryTextColor}}>On Hold: </label>
										<label style={{
											color: Colors.primaryTextColor,
											marginLeft: 5
										}}>${securityHold}</label>
									</div>
									<div>
										<label style={{color: Colors.secondaryTextColor}}>Captured: </label>
										<label style={{
											color: Colors.primaryTextColor,
											marginLeft: 5
										}}>${securityCaptured}</label>
									</div>
									<div>
										<label style={{color: Colors.secondaryTextColor}}>Balance: </label>
										<label style={{
											color: Colors.primaryTextColor,
											marginLeft: 5
										}}>${outstandingSecurityBalance}</label>
									</div>
								</div>
								:
								<div style={{
									marginTop: 10,
									width: '100%',
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'space-between',
								}}>
									<div>
										<label style={{color: Colors.secondaryTextColor}}>Total Paid: </label>
										<label style={{
											color: Colors.primaryTextColor,
											marginLeft: 5
										}}>${balancePaid}</label>
									</div>
									<div>
										<label style={{color: Colors.secondaryTextColor}}>Balance: </label>
										<label style={{
											color: Colors.primaryTextColor,
											marginLeft: 5
										}}>${outstandingBalance}</label>
									</div>
								</div>
						}
					</div>
					<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 4}}>
						<label
							onClick={() => setShowCheckout(false)}
							style={{
								color: Colors.secondaryTextColor,
								width: '100%',
								textAlign: 'center',
								textDecoration: 'underline',
								cursor: 'pointer'
							}}>
							Change/Cancel
						</label>
					</div>
					{
						isSecurity ?
							<div style={{width: '40%', margin: '0 auto'}}>
								{renderNewCardForm()}
							</div>
							:
							isPaymentStripe ?
								<iframe src={url} height={'100%'} width={'100%'}/>
								:
								<div style={{width: '40%', margin: '0 auto'}}>
									{renderNewCardForm()}
								</div>
					}

				</div>
				// <div id="checkout" style={{width: '100%', height: '100%'}}>
				// 	<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10}}>
				// 		<label
				// 			onClick={() => setShowCheckout(false)}
				// 			style={{color: Colors.secondaryTextColor, width: '100%', textAlign: 'center', textDecoration: 'underline', cursor: 'pointer'}}>
				// 			Change/Cancel
				// 		</label>
				// 	</div>
				// 	<EmbeddedCheckoutProvider
				// 		stripe={stripePromise}
				// 		options={options}
				// 		onComplete={() => {
				// 			console.log('here here')
				// 			setShowCheckout(false)
				// 			onModalClose()
				// 		}}
				// 	>
				// 		<EmbeddedCheckout/>
				// 	</EmbeddedCheckoutProvider>
				// </div>
			)
		} else {
			return (
				<div style={{width: '39%', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
					{
						isSecurity ?
							<div style={{
								width: '100%',
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'space-between',
								marginTop: 10
							}}>
								<div>
									<label style={{color: Colors.secondaryTextColor}}>On Hold: </label>
									<label
										style={{color: Colors.primaryTextColor, marginLeft: 5}}>${securityHold}</label>
								</div>
								<div>
									<label style={{color: Colors.secondaryTextColor}}>Captured: </label>
									<label style={{
										color: Colors.primaryTextColor,
										marginLeft: 5
									}}>${securityCaptured}</label>
								</div>
								<div>
									<label style={{color: Colors.secondaryTextColor}}>Balance: </label>
									<label style={{
										color: Colors.primaryTextColor,
										marginLeft: 5
									}}>${outstandingSecurityBalance}</label>
								</div>
							</div>
							:
							<div style={{
								marginTop: 10,
								width: '100%',
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}>
								<div>
									<label style={{color: Colors.secondaryTextColor}}>Total Paid: </label>
									<label
										style={{color: Colors.primaryTextColor, marginLeft: 5}}>${balancePaid}</label>
								</div>
								<div>
									<label style={{color: Colors.secondaryTextColor}}>Balance: </label>
									<label style={{
										color: Colors.primaryTextColor,
										marginLeft: 5
									}}>${outstandingBalance}</label>
								</div>
							</div>
					}
					{
						isSecurity ?
							<CustomTextFieldNew
								marginTop={10}
								width={'100%'}
								label={'Security Hold($)'}
								placeholder={'security hold amount'}
								value={securityPayable}
								onChange={(text) => {
									if (isNaN(text))
										return
									setSecurityPayable(text)
								}}
							/>
							:
							<CustomTextFieldNew
								marginTop={10}
								width={'100%'}
								label={'Balance Payable($)'}
								placeholder={'balance payable'}
								value={balancePayable}
								onChange={(text) => {
									if (isNaN(text))
										return
									setBalancePayable(text)
								}}
							/>
					}

					<CustomTextFieldNew
						marginTop={10}
						width={'100%'}
						label={'Email Address'}
						placeholder={'email address'}
						value={email}
						onChange={setEmail}
					/>

					<CustomButtonContained
						text={'Pay Now'}
						onClick={onShowCheckoutClicked}
						style={{marginTop: 2}}
						color={'primary'}
					/>
					{/*<Tab tab={tab} onChange={(tab) => {*/}
					{/*	if (selectedClient && selectedClient?.customerProfileId) getCardsOnFile()*/}
					{/*	setTab(tab)*/}
					{/*}}/>*/}

					{/*{*/}
					{/*	tab === 0 ?*/}
					{/*		renderNewCardForm()*/}
					{/*		:*/}
					{/*		renderExistingCards()*/}
					{/*}*/}

				</div>
			)
		}
	}
	const renderDetails = () => {
		if (!selectedRes)
			return null

		return (
			<div
				style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%'}}>
				{
					selectedRes && selectedRes.value &&
					renderPaymentForm()
				}
			</div>
		)
	}

	const renderContent = () => {
		let resOptions = allRes.map(res => {
			return {label: `Res #${res._id}-${res.vehicle_id}`, value: res._id}
		})

		return (
			<div style={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				height: '95%',
				overflowY: 'scroll'
			}}>
				<label className={GeneralStyles.darkText} style={{fontWeight: 600, fontSize: 20, marginTop: 20}}>
					Process Online Payment
				</label>
				<label className={GeneralStyles.grayText} style={{fontSize: 18, marginTop: 5}}>
					{isSecurity ? 'Security Deposit' : 'New Payment'}
				</label>
				{
					showError &&
					<label style={{width: '100%', textAlign: 'center', color: 'red'}}>{errorMessage}</label>
				}
				{
					showSuccess &&
					<label style={{width: '100%', textAlign: 'center', color: 'green'}}>{successMessage}</label>
				}
				<div
					className={GeneralStyles.boxShadow}
					style={{width: '40%', height: 40, borderRadius: 10, marginTop: 12}}>
					<CustomSelect
						disabled={reservation !== null}
						placeholder={'select or search reservation'}
						borderColor={'transparent'}
						value={selectedRes}
						options={resOptions}
						onChange={(value) => {
							setShowCheckout(false)
							setSelectedRes(value)
						}}
					/>
				</div>
				{
					showLoader ?
						<div
							style={{
								height: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								marginTop: 50
							}}>
							<CustomLoaderSpinner/>
						</div>
						:
						renderDetails()
				}
			</div>
		)
	}

	return (
		<CustomModal
			show={modalIsOpen}
			handleClose={onModalClose}
			containerWidth={window.innerWidth - 200}
			containerHeight={window.innerHeight - 50}
		>
			{renderContent()}
		</CustomModal>
	)
}

const Tab = ({tab, onChange}) => {
	return (
		<div style={{
			width: '98%',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			borderRadius: 10,
			border: '1px solid gray',
			padding: 4,
			backgroundColor: Colors.theme,
			borderColor: Colors.theme,
			marginTop: 20
		}}>
			<div
				className={Styles.tabEntry}
				style={{backgroundColor: tab === 0 ? Colors.tabButtonTheme : 'transparent',}}
				onClick={() => onChange(0)}>
				<p style={{fontSize: Constants.entryTextSize, color: Colors.tertiaryTextColor}}>New Card</p>
			</div>
			<div
				className={Styles.tabEntry}
				style={{backgroundColor: tab === 1 ? Colors.tabButtonTheme : 'transparent',}}
				onClick={() => onChange(1)}>
				<p style={{fontSize: Constants.entryTextSize, color: Colors.tertiaryTextColor}}>Cards On File</p>
			</div>
		</div>
	)
}
